.row {
  display: flex;
  width: 100%;
  justify-content: center;
}

.leftColumn {
  text-align: right;
  margin-right: 10px;
  width: 47%;
}

.rightColumn {
  width: 47%;
  text-align: left;
}
