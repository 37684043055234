.changeView {
  display: flex;
  width: 100%;
  justify-content: flex-start
}

.value {
  margin-right: 20px;
  display: block;
}
.changeView:hover .newValue {
  display: none;
}
.oldValue {
  display: none;
}
.changeView:hover .oldValue {
  display: block;
}

.changeType {
  color: red;
}
