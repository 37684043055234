.ReactTable .rt-noData {
  left: unset;
  order: 1;
  position: initial;
  text-align: center;
  top: unset;
  transform: unset;
}
.pagination-bottom {
  order: 2;
}