.Collapsible {
  background-color: white;
}

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid grey;
  border-top: 0;
}

.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;

 :last-child {
    margin-bottom: 0;
  }
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: grey;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #1B9AF7;
  color: white;
}

.Collapsible__trigger:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f107';
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}

.Collapsible__trigger:global(.is-open):after {
  transform: rotateZ(180deg);
  -webkit-transform: rotateZ(180deg);
}

.Collapsible__trigger .is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
  
  
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black;
}
